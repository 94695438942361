<template>
  <b-container fluid>
    <b-row>
        <b-col v-if="this.accommodation.org_id">
            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
            <template v-slot:projectNameSlot>
                {{ }}
            </template>
            {{  }}
            </list-report-head>
        </b-col>
    </b-row>
    <b-row>
      <b-overlay :show="loading">
        <div class="invoice text-dark pl-5" id="printArea">
          <div class="invoice-logo"></div>
          <div class="invoice-sec-1">
            <div class="invoice-sec-1-ref">
              <div class="to-invoice">
                <p>{{$t('globalTrans.to')}},</p>
                <p>
                  {{$t('elearning_config.fiscal_year')}}:
                  {{ ($i18n.locale==='bn') ? accommodation.fiscal_year_bn : accommodation.fiscal_year }}
                </p>
                <p>
                  {{$t('globalTrans.organization')}}:
                  {{ ($i18n.locale==='bn') ? accommodation.org_bn : accommodation.org }}
                </p>
                <p>
                  {{ $t('elearning_config.office_type') }}:
                  {{ $i18n.locale === 'bn' ? accommodation.office_type_bn : accommodation.office_type }}
                </p>
                <p>
                  {{ $t('globalTrans.office') }}:
                  {{ $i18n.locale === 'bn' ? accommodation.office_bn : accommodation.office }}
                </p>
                <p>
                  {{ $t('globalTrans.name') }}:
                  {{ $i18n.locale === 'bn' ? accommodation.name_bn : accommodation.name }}
                </p>
                <p>
                  {{ $t('globalTrans.mobile') }}:
                  {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(accommodation.mobile, { useGrouping: false }) }}
                </p>
              </div>
            </div>
            <div class="invoice-sec-1-date">
              <b-table-simple bordered>
                <b-tr>
                  <b-th style="width:20%" class="text-center">{{ $t('elearning_config.room_type') }}</b-th>
                  <b-th style="width:15%" class="text-center">{{ $t('elearning_config.floor_no') }}</b-th>
                  <b-th style="width:15%" class="text-center">{{ $t('elearning_config.room_no') }}</b-th>
                  <b-th style="width:15%" class="text-center">{{ $t('elearning_config.room_rent') }}</b-th>
                  <b-th style="width:15%" class="text-center">{{ $t('globalTrans.totalAmount') }}</b-th>
                </b-tr>
                <b-tr>
                  <b-td style="width:20%" class="text-center">{{ $i18n.locale === 'bn' ? accommodation.room_type_bn : accommodation.room_type }}</b-td>
                  <b-td style="width:15%" class="text-right">{{ $i18n.locale === 'bn' ? accommodation.floor_no : accommodation.floor_no }}</b-td>
                  <b-td style="width:15%" class="text-right">{{ $i18n.locale === 'bn' ? accommodation.room_no : accommodation.room_no }}</b-td>
                  <b-td style="width:15%" class="text-right">{{ $n(accommodation.room_rent) }}</b-td>
                  <b-td style="width:15%" class="text-right">{{ $n(accommodation.total_rent) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-th colspan="4" class="text-right">{{ $t('globalTrans.tax') }}(%)</b-th>
                    <b-td class="text-right">{{ $n(accommodation.tax) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-th colspan="4" class="text-right">{{ $t('elearning_accommodation.service_charge') }}</b-th>
                    <b-td class="text-right">{{ $n(accommodation.service_charge) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-th colspan="4" class="text-right">{{ $t('elearning_accommodation.other_deduction') }}</b-th>
                    <b-td class="text-right">{{ $n(accommodation.other_deduction) }}</b-td>
                </b-tr>
                <b-tr>
                    <b-th colspan="4" class="text-right">{{ $t('globalTrans.total') }}</b-th>
                    <b-td class="text-right">{{ $n(accommodation.payment_amount + accommodation.service_charge) }}</b-td>
                </b-tr>
              </b-table-simple>
            </div>
            <div class="invoice-sec-1-date">
              <p>
                {{$t('globalTrans.date')}}: {{ accommodation.bill_generate_date | dateFormat }}
              </p>
            </div>
          </div>
          <div class="invoice-greeting">
            <p>{{$t('globalTrans.thank_you')}}</p>
          </div>
        </div>
      </b-overlay>
    </b-row>
  </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 12px;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
// import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
// import { accommodationManagementShow } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'

export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    const tmp = this.getFormData()
      this.accommodation = tmp
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
      officeTypeList: [],
      accommodation: {},
      guestHouseList: [],
      officeList: [],
      loading: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    }
  },
  watch: {
    'accommodation.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'accommodation.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
        this.guestHouseList = this.getGuestHouseList(newValue)
    }
  },
  methods: {
      getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    // async getAccommodationData () {
    //   this.loading = true
    //   const tmpData = this.$store.state.list.find(item => item.id === this.id)
    //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, accommodationManagementShow + '/' + tmpData.accommodation_management_id)
    //   this.accommodation = JSON.parse(JSON.stringify(result.data))
    //   const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(this.accommodation.org_id))
    //         const orgData = {}
    //         if (typeof orgObj !== 'undefined') {
    //           orgData.org = orgObj.text_en
    //           orgData.org_bn = orgObj.text_bn
    //         } else {
    //           orgData.org = ''
    //           orgData.org_bn = ''
    //         }

    //         const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(this.accommodation.guest_house_id))
    //         const guestHouseData = {}
    //         if (typeof guestHouseObj !== 'undefined') {
    //           guestHouseData.guest_house = guestHouseObj.text_en
    //           guestHouseData.guest_house_bn = guestHouseObj.text_bn
    //         } else {
    //           guestHouseData.guest_house = ''
    //           guestHouseData.guest_house_bn = ''
    //         }

    //          const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(this.accommodation.office_type_id))
    //         const officeTypeData = {}
    //         if (typeof officeTypeObj !== 'undefined') {
    //           officeTypeData.office_type = officeTypeObj.text_en
    //           officeTypeData.office_type_bn = officeTypeObj.text_bn
    //         } else {
    //           officeTypeData.office_type = ''
    //           officeTypeData.office_type_bn = ''
    //         }
    //          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(this.accommodation.office_id))
    //         const officeData = {}
    //         if (typeof officeObj !== 'undefined') {
    //           officeData.office = officeObj.text_en
    //           officeData.office_bn = officeObj.text_bn
    //         } else {
    //           officeData.office = ''
    //           officeData.office_bn = ''
    //         }

    //         const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(this.accommodation.fiscal_year_id))
    //         const fiscalYearData = {}
    //         if (typeof fiscalYearObj !== 'undefined') {
    //           fiscalYearData.fiscal_year = fiscalYearObj.text_en
    //           fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
    //         } else {
    //           fiscalYearData.fiscal_year = ''
    //           fiscalYearData.fiscal_year_bn = ''
    //         }

    //         const OfficeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(this.accommodation.office_id))
    //         const OfficeData = {}
    //         if (typeof OfficeObj !== 'undefined') {
    //           OfficeData.office_name = OfficeObj.text_en
    //           OfficeData.office_name_bn = OfficeObj.text_bn
    //         } else {
    //           OfficeData.office_name = ''
    //           OfficeData.office_name_bn = ''
    //         }

    //         const foodPackageObj = this.$store.state.TrainingElearning.commonObj.foodPackageList.find(doc => doc.value === parseInt(this.accommodation.food_package_id))
    //         const foodPackageData = {}
    //         if (typeof foodPackageObj !== 'undefined') {
    //           foodPackageData.foor_package = foodPackageObj.text_en
    //           foodPackageData.foor_package_bn = foodPackageObj.text_bn
    //         } else {
    //           foodPackageData.foor_package = ''
    //           foodPackageData.foor_package_bn = ''
    //         }

    //         this.accommodation = this.accommodation.map(item => {
    //           const roomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(roomTypeList => roomTypeList.value === parseInt(item.room_type_id))
    //           const roomTypeData = {}
    //           if (typeof roomTypeObj !== 'undefined') {
    //             roomTypeData.room_type = roomTypeObj.text_en
    //             roomTypeData.room_type_bn = roomTypeObj.text_bn
    //           } else {
    //             roomTypeData.room_type = ''
    //             roomTypeData.room_type_bn = ''
    //           }
    //           const roomObj = this.$store.state.TrainingElearning.commonObj.roomList.find(roomTypeList => roomTypeList.value === parseInt(item.room_no))
    //           const roomData = {}
    //           if (typeof roomObj !== 'undefined') {
    //             roomData.room = roomObj.text_en
    //             roomData.room_bn = roomObj.text_bn
    //           } else {
    //             roomData.room = ''
    //             roomData.room_bn = ''
    //           }
    //           return Object.assign({}, item, roomTypeData, roomData)
    //         })

    //       officeTypeData.payment_amount = tmpData.total_amount
    //       officeTypeData.tax = tmpData.tax
    //       officeTypeData.service_charge = tmpData.service_charge
    //       officeTypeData.other_deduction = tmpData.other_deduction
    //       this.accommodation = Object.assign({}, this.accommodation, orgData, guestHouseData, OfficeData, fiscalYearData, officeTypeData, foodPackageData, officeData)
    //     this.loading = false
    // },
    getOfficeTypeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    getOfficeList (officeTypeId = null) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
            return officeList.filter(office => office.office_type_id === officeTypeId)
        }
        return officeList
    },
    getGuestHouseList (officeTypeId = null) {
        const houseList = this.$store.state.TrainingElearning.commonObj.guestHouseList.filter(item => item.status === 1)
        if (officeTypeId) {
            return houseList.filter(office => office.office_type_id === officeTypeId)
        }
        return houseList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tpm.payment')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.accommodation)
    }
  }
}
</script>
